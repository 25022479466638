import React from 'react';

const sections = [
  {
    title: 'Acceptance of Terms:',
    content: [
      'By accessing or using our website, you agree to be bound by these Terms and Conditions and all applicable laws and regulations. If you do not agree with any of these terms, please do not use our website.'
    ]
  },
  {
    title: 'Intellectual Property:',
    content: [
      'All content on our website, including but not limited to text, graphics, logos, images, audio clips, video clips, data compilations, software, and other materials (collectively, the "Content"), is owned by or licensed to us and is protected by copyright, trademark, and other intellectual property laws.',
      'You may not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store or transmit any Content on our website without our prior written consent.'
    ]
  },
  {
    title: 'Use of the Website:',
    content: [
      'You agree to use our website only for lawful purposes and in a manner that does not infringe the rights of, or restrict or inhibit the use and enjoyment of, our website by any third party.'
    ]
  },
  {
    title: 'You agree not to use our website:',
    content: [
      'In any way that breaches any applicable local, national, or international law or regulation',
      'To transmit any unsolicited or unauthorized advertising or promotional material or any other form of solicitation',
      'To knowingly transmit any data or send or upload any material that contains viruses, Trojan horses, worms, time-bombs, keystroke loggers, spyware, adware, or any other harmful programs or similar computer code designed to adversely affect the operation of any computer software or hardware.',
      'Links to Third-Party Websites',
      'Our website may contain links to third-party websites that are not owned or controlled by us. We assume no responsibility for the content, privacy policies or practices of any third-party websites. You acknowledge and agree that we are not responsible for any damages or losses caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such third-party websites.'
    ]
  },
  {
    title: 'Disclaimer of Warranties:',
    content: [
      'Our website and the Content are provided "as is" and without warranty of any kind, whether express or implied. We make no representations or warranties of any kind as to the accuracy, completeness, reliability, timeliness, availability, or suitability of the website or the Content.'
    ]
  },
  {
    title: 'Limitation of Liability:',
    content: [
      'In no event shall we be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in any way connected with the use of our website or the Content, even if we have been advised of the possibility of such damages.'
    ]
  },
  {
    title: 'Indemnification:',
    content: [
      'You agree to indemnify, defend, and hold harmless us, our affiliates, officers, directors, employees, agents, licensors, and suppliers from and against all claims, losses, expenses, damages, and costs, including reasonable attorneys\' fees, arising out of or relating to your use of our website or violation of these Terms and Conditions.'
    ]
  },
  {
    title: 'Governing Law:',
    content: [
      'These Terms and Conditions shall be governed by and construed in accordance with the laws of Indore. Any dispute arising out of or in connection with these Terms and Conditions shall be subject to the exclusive jurisdiction of the courts of Indore.'
    ]
  },
  {
    title: 'Changes to Terms and Conditions:',
    content: [
      'We reserve the right to change these Terms and Conditions at any time without notice to you. Your continued use of our website following any changes to these Terms and Conditions constitutes your acceptance of those changes.'
    ]
  }
];

const TermsConditions = () => {
  return (
    <>
      {/* ======= Terms-conditions section start=============== */}
      <div className="container-fluid terms">
        <div className="row">
          <div className="col-12 section banner mt-5">
            <div className="container h-100">
              <div className="row justify-content-between align-items-center h-100">
                <div className="col-md-12 content-main">
                  <h1 className="text-dark mb-3" data-aos="fade-up" data-aos-delay="250">
                    Terms and Conditions
                  </h1>
                  <h5 className="text-dark fw-medium mb-100" data-aos="fade-up" data-aos-delay="350">
                    Last updated: October 10, 2023
                  </h5>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 section job-info pb-0">
            <div className="container">
              {sections.map((section, index) => (
                <div className="col-12 item" key={index}>
                  <div className="col-12 title-main">
                    <h4 className="fw-bold mb-0">{section.title}</h4>
                  </div>
                  <div className="col-md-10 mx-auto">
                    {section.content.map((paragraph, pIndex) => (
                      <h6 key={pIndex}>{paragraph}</h6>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* ======= Terms-conditions section end=============== */}
    </>
  );
};

export default TermsConditions;
