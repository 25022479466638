import React from 'react';

const servicesData = [
  {
    img: 'Group.svg',
    title: 'Explore the Complete Range of Product-Centric Technological Solutions',
    text: 'Introducing Lait Mittal Technology Pvt Ltd, your one-stop source for all-encompassing digital solutions. We are a pioneering technology company, propelled by a cadre of immensely talented experts adept at conquering the realms of mobile, web, and UI/UX hurdles. Our mastery resides in the art of fashioning extraordinary digital products, underpinned by an unwavering commitment to excellence.',
    imgAlt: 'Service',
  },
];

const serviceCards = [
  {
    title: 'Mobile Application Development',
    text: 'Our dedicated team of experts is committed to crafting bespoke mobile app solutions for iOS, Android, and Windows, harnessing state-of-the-art technologies to tackle unique and intricate business challenges.',
    logo: 'mobile.svg',
    imgAlt: 'Mobile Development',
    tech: ['android', 'java', 'kotlin', 'swift', 'flutter', 'react'],
    mainImg: 'mobile_service1.jpg',
    mainImgAlt: 'Mobile Service',
  },
  {
    title: 'Website Development Solutions',
    text: 'At Lait Mittal Technology Pvt Ltd, we blend creative web solutions with the latest technologies to create a strong foundation for innovative businesses. Our unwavering focus is on constructing and delivering excellence at every stage.',
    logo: 'web.svg',
    imgAlt: 'Web Development',
    tech: ['html', 'css', 'javascript', 'react', 'angular', 'node'],
    mainImg: 'web_service.png',
    mainImgAlt: 'Web Service',
  },
  {
    title: 'UI & UX Design',
    text: 'We firmly believe that the aesthetics of a website or mobile app are just as crucial as its functionality. We infuse personality into every project through blueprints that radiate originality.',
    logo: 'ui.svg',
    imgAlt: 'UI/UX Design',
    tech: ['figma', 'ps', 'adobe', 'adobexd'],
    mainImg: 'ui_service1.png',
    mainImgAlt: 'UI Service',
  },
  {
    title: 'QA Solutions',
    text: 'Launching an online platform demands perfection, which is why we subject your projects to rigorous testing procedures to achieve the utmost precision.',
    logo: 'qa.svg',
    imgAlt: 'QA Solutions',
    tech: ['trello', 'jira'],
    mainImg: 'qa_service.png',
    mainImgAlt: 'QA Service',
  },
];

const Service = () => {
  return (
    <section id="service" className="section">
      {servicesData.map((service, index) => (
        <div key={index} className="carrier_bnr">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="bnr_left mb-2">
                  <img src={`assets/img/service/${service.img}`} alt={service.imgAlt} />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 m-lg-auto m-md-auto">
                <div className="bnr_right">
                  <div className="text_section p-4">
                    <h1>{service.title}</h1>
                    <p>{service.text}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}

      <div className="services_type">
        {serviceCards.map((card, index) => (
          <div key={index} className="container mt-4">
            <div className="row shadow-card">
              {index % 2 === 0 ? (
                <>
                  <div className="col-lg-6 col-md-6 mt-4">
                    <div className="crd_main">
                      <div className="img_sec">
                        <img src={`assets/img/service/logo/${card.logo}`} alt={card.imgAlt} />
                      </div>
                      <div className="text_section">
                        <h3>{card.title}</h3>
                        <p>{card.text}</p>
                      </div>
                      <div className="img_section">
                        {card.tech.map((tech) => (
                          <div className="sub_img" key={tech}>
                            <div className="coding_img">
                              <img src={`assets/img/service/all_tec/${tech}.svg`} alt={tech} />
                            </div>
                            <p>{tech.charAt(0).toUpperCase() + tech.slice(1)}</p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 m-auto">
                    <div>
                      <img src={`assets/img/service/${card.mainImg}`} className="img-fluid mt-4" width={400} alt={card.mainImgAlt} />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="col-lg-6 col-md-6 m-auto">
                    <div>
                      <img src={`assets/img/service/${card.mainImg}`} className="img-fluid mt-4" width={400} alt={card.mainImgAlt} />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 mt-4">
                    <div className="crd_main">
                      <div className="img_sec">
                        <img src={`assets/img/service/logo/${card.logo}`} alt={card.imgAlt} />
                      </div>
                      <div className="text_section">
                        <h3>{card.title}</h3>
                        <p>{card.text}</p>
                      </div>
                      <div className="img_section">
                        {card.tech.map((tech) => (
                          <div className="sub_img" key={tech}>
                            <div className="coding_img">
                              <img src={`assets/img/service/all_tec/${tech}.svg`} alt={tech} />
                            </div>
                            <p>{tech.charAt(0).toUpperCase() + tech.slice(1)}</p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Service;
