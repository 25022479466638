import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

const Contact = () => {
  const [error, setError] = useState(false);
  const [addContact, setAddContact] = useState({
    name: '',
    email: '',
    mobileNumber: '',
    subject: '',
    message: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAddContact((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, email, mobileNumber, subject, message } = addContact;

    if (!name || !email || !mobileNumber || !subject || !message) {
      setError(true);
      return;
    }

    try {
      const response = await axios.post('http://13.201.225.123:3000/website/contact-us', addContact);
      if (response.status === 201) {
        toast.success('Message sent successfully!', { autoClose: 2000 });
        setError(false);
        setAddContact({
          name: '',
          email: '',
          mobileNumber: '',
          subject: '',
          message: '',
        });
      } else {
        toast.error('Failed to send message. Please try again later.', { autoClose: 2000 });
      }
    } catch (error) {
      console.error('Something went wrong', error);
      toast.error('Failed to send message. Please try again later.', { autoClose: 2000 });
    }
  };

  return (
    <section id="contact" className="section">
      <ToastContainer />
      <div className="carrier_bnr contact_form">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 m-auto">
              <div className="bnr_left revealer">
                <img src="assets/img/contact/contact_hero.png" alt="Contact Hero" />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 m-lg-auto m-md-auto">
              <div className="bnr_right">
                <div className="text_section">
                  <h1 className="revealer mb-4">Empower Your Business with Us.</h1>
                  <p>
                    Our team is here to support you every step of the way. Whether you're a business owner, a software professional, or just passing through, if you have any questions or inquiries about our services, solutions, or products, don't hesitate to get in touch with us using the form below. We're here to help you thrive.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="lets_talk">
        <div className="container mb-4">
          <section className="contact_services section-bg">
            <div className="container">
              <div className="row">
               
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay={100}>
                  <div className="icon-box icon-box-blue">
                    <div className="icon">
                      <img src="assets/img/contact/address.svg" className="img-fluid contact-img mb-4" alt="Address" />
                    </div>
                    <h4 className="title">
                      <a href="https://maps.app.goo.gl/q3uejjz5bbADhsai9" target="_blank" rel="noopener noreferrer">
                        Visit Us
                      </a>
                    </h4>
                    <p className="description">
                      <a href="https://maps.app.goo.gl/q3uejjz5bbADhsai9" target="_blank" rel="noopener noreferrer">
                        2939, 2nd Floor, Hamilton Road, <br /> Kashmiri Gate, Delhi, 110006
                      </a>
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay={200}>
                  <div className="icon-box icon-box-green">
                    <div className="icon">
                      <img src="assets/img/contact/email.svg" className="img-fluid contact-img mb-4" alt="Email" />
                    </div>
                    <h4 className="title">
                      <a href="mailto:contact@lalitmittaltechnologies.com">Mail Us</a>
                    </h4>
                    <p className="description">
                      <a href="mailto:contact@lalitmittaltechnologies.com">contact@lalitmittaltechnologies.com</a>
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay={200}>
                  <div className="icon-box icon-box-pink">
                    <div className="icon">
                      <img src="assets/img/contact/24in7.svg" className="img-fluid contact-img mb-4" alt="24/7 Support" />
                    </div>
                    <h4 className="title">
                      <a href="#">Customer Support</a>
                    </h4>
                    <p className="description">
                      <a href="#">24/7 Customer Support is Accessible</a>
                    </p>
                  </div>
                </div>              
              </div>
            </div>
          </section>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mt-4">
              <div className="main_heading">
                <h1 className="revealer">Let’s Talk</h1>
              </div>
              <div className="contact_frm">
                <div className="frm_header">
                  <h4 className="revealer">Let’s get in touch with us</h4>
                  <img className="revealer" src="assets/img/contact/contact_mail.svg" alt="Contact Mail" />
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="frm_middle">
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <div className="form_spacing">
                          <label className="revealer form-label">Your Name</label>
                          <input
                            type="text"
                            onChange={handleInputChange}
                            className="revealer effect-2 form-control"
                            id="name"
                            name="name"
                            value={addContact.name}
                            placeholder="Enter Your Name"
                            required
                          />
                          {error && !addContact.name && <span className="error">Please enter your name</span>}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form_spacing">
                          <label className="revealer form-label">Email Address*</label>
                          <input
                            type="email"
                            onChange={handleInputChange}
                            className="revealer effect-2 form-control"
                            name="email"
                            id="email"
                            value={addContact.email}
                            placeholder="Enter Your Email Address"
                            required
                          />
                          {error && !addContact.email && <span className="error">Please enter your email</span>}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form_spacing">
                          <label className="revealer form-label">Contact Number*</label>
                          <input
                            type="tel"
                            onChange={handleInputChange}
                            className="revealer effect-2 form-control"
                            value={addContact.mobileNumber}
                            name="mobileNumber"
                            id="mobileNumber"
                            placeholder="+91 123456789"
                            pattern="[0-9]{10}"
                            required
                          />
                          {error && !addContact.mobileNumber && <span className="error">Please enter your number</span>}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form_spacing">
                          <label className="revealer form-label">Subject</label>
                          <input
                            type="text"
                            onChange={handleInputChange}
                            className="revealer effect-2 form-control"
                            id="subject"
                            name="subject"
                            value={addContact.subject}
                            placeholder="Enter Your Subject"
                            required
                          />
                          {error && !addContact.subject && <span className="error">Please enter your subject</span>}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form_spacing">
                          <label className="revealer form-label">How can we help?*</label>
                          <textarea
                            onChange={handleInputChange}
                            className="revealer effect-2 form-control"
                            name="message"
                            id="message"
                            value={addContact.message}
                            placeholder="Please enter message & comment"
                            required
                          />
                          {error && !addContact.message && <span className="error">Please enter your message</span>}
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12">
                        <div className="btn_submit">
                          <button type="submit" className="revealer submit btn">Submit</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="msgdiv mt-3" />
                </form>
              </div>
            </div>
            <div className="col-lg-6 mt-4">
              <div className="main_heading">
                <h1 className="revealer">Location:</h1>
              </div>
              <div>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3503.2456251553946!2d77.2254464!3d28.6628167!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfd0f6ed74bb5%3A0x36f56dd9791e012c!2sLalit%20Mittal%20Technologies%20Pvt%20Ltd.!5e0!3m2!1sen!2sin!4v1647329120553!5m2!1sen!2sin"
                  frameBorder="0"
                  style={{ border: '1px solid #6F6F6F', borderRadius: '8px', width: '100%', height: '418px' }}
                  allowFullScreen
                  loading="lazy"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="works">
        <div className="container">
          <div className="main_heading">
            <h1 className="revealer">How It All Works</h1>
          </div>
          <div className="works_sub zoom1">
            <div className="row g-lg-5">
              <div className="col-lg-4 col-md-4 mb-4 mb-lg-0">
                <div className="works_card tilt" data-tilt>
                  <div className="card_top">
                    <p>1</p>
                  </div>
                  <img src="assets/img/contact/1.svg" alt="Step 1" className="revealer" />
                  <div className="content">
                    <h4 className="revealer">Product Inquiry</h4>
                    <p className="revealer">
                      If you have any questions or need information about our software products, please don't hesitate to contact us via email. We're here to address your inquiries and provide the information you need. You can also submit a request to discuss potential product collaborations. We're excited to help you for software product's success.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 mb-4 mb-lg-0">
                <div className="works_card tilt" data-tilt>
                  <div className="card_top">
                    <p>2</p>
                  </div>
                  <img src="assets/img/contact/2.svg" alt="Step 2" className="revealer" />
                  <div className="content">
                    <h4 className="revealer">Product Quality</h4>
                    <p className="revealer">
                      Our team is ready to provide you a quality software products, including pricing and collaboration opportunities. We're eager to explore how our software solutions can align with your specific needs and objectives.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="works_card tilt" data-tilt>
                  <div className="card_top">
                    <p>3</p>
                  </div>
                  <img src="assets/img/contact/3.svg" alt="Step 3" className="revealer" />
                  <div className="content">
                    <h4 className="revealer">Initiating Collaboration</h4>
                    <p className="revealer">
                      When you're prepared to begin a collaboration to integrate our software products into your projects, we're fully equipped to guide you at every stage. Let's commence the journey to enhance your projects with our software solutions.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
