import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

const Career = () => {
  const [error, setError] = useState(false);
  const [publicKey, setPublicKey] = useState('');
  const [addData, setAddData] = useState({
    name: '',
    email: '',
    mobileNumber: '',
    message: '',
    jobPosition: '',
    attachment: ''
  });

  const handleInputChange = (e) => {
    setAddData({
      ...addData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFileChange = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    try {
      const response = await axios.post('http://13.201.225.123:3000/file', formData);
      const publicId = response.data.publicId;
      setPublicKey(publicId);
    } catch (error) {
      setError(true);
      console.log('Error uploading file:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!addData.name || !addData.email || !addData.mobileNumber || !publicKey || !addData.message || !addData.jobPosition) {
      setError(true);
      return;
    }
    try {
      const response = await axios.post('http://13.201.225.123:3000/website/carrier', {
        ...addData,
        attachment: publicKey,
      });
      if (response.status === 201) {
        toast.success('Message sent successfully!', { autoClose: 2000 });
        setError(false);
        document.getElementById('apply_now').classList.remove('show');
        setAddData({
          name: '',
          email: '',
          mobileNumber: '',
          message: '',
          jobPosition: '',
          attachment: ''
        });
        document.getElementById('attachment').value = '';
        setPublicKey('');
      } else {
        toast.error('Failed to send message. Please try again later.', { autoClose: 2000 });
      }
    } catch (error) {
      console.log('Error submitting form data:', error);
      toast.error('Failed to send message. Please try again later.');
      setAddData({
        name: '',
        email: '',
        mobileNumber: '',
        message: '',
        jobPosition: '',
        attachment: ''
      });
      document.getElementById('attachment').value = '';
      setPublicKey('');
      setError(false);
    }
  };

  const jobOpenings = [
    { img: 'android.svg', title: 'Android', exp: '3 to 5 years' },
    { img: 'ios.svg', title: 'iOS', exp: '3 to 5 years' },
    { img: 'java.svg', title: 'Java', exp: '3 to 5 years' },
    { img: 'react.svg', title: 'React Js Developer', exp: '3 to 5 years' },
    { img: 'angular.svg', title: 'Angular Developer', exp: '3 to 5 years' },
    { img: 'node.svg', title: 'Node Js Developer', exp: '3 to 5 years' },
    { img: 'ui.svg', title: 'UI/UX Designer', exp: '3 to 7 years' },
    { img: 'wordpress.png', title: 'WordPress Developer', exp: '3 to 5 years' },
    { img: 'marketing.png', title: 'Digital Marketing Specialist', exp: '3 to 5 years' },
  ];

  const benefits = [
    { img: 'offer_1.svg', title: 'Exciting Projects', description: 'Join us for the opportunity to work on diverse and engaging projects with great clients.' },
    { img: 'offer_2.svg', title: 'Talented Team', description: 'Be part of a quality team of friendly and professional developers and designers.' },
    { img: 'offer_3.svg', title: 'Flexibility', description: 'Enjoy the freedom to set your own work hours while contributing to various projects.' },
    { img: 'offer_4.svg', title: 'Continuous Improvement', description: 'Benefit from monthly performance reviews to learn from mistakes and grow.' },
    { img: 'offer_5.svg', title: 'Personal Growth', description: 'Receive research-based support for your personal development and career planning.' },
    { img: 'offer_6.svg', title: 'Work-Life Balance', description: 'We prioritize work-life balance and promote team outings for a strong, connected team.' },
  ];

  return (
    <>
      <div id="career" className="section">
        <ToastContainer />
        <section>
          <div className="carrier_bnr">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="bnr_left">
                    <img src="assets/img/career/career.png" loading="lazy" alt="Career" />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 m-lg-auto m-md-auto">
                  <div className="bnr_right">
                    <div className="text_section">
                      <h1>If you haven't discovered the role you're searching for...</h1>
                      <p>At Lalit Mittal Technologies Pvt Ltd., we are committed to nurturing a culture of collaboration and harmony. Our mission is to build a united and high-achieving team where team members bolster one another and collectively pursue the path to success.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="carrier_bnr_bottom" />

          <div className="opnings">
            <div className="container">
              <div className="main_heading">
                <h1>Current Openings</h1>
              </div>
              <div className="opening_sub">
                <div className="row g-lg-5 g-md-4">
                  {jobOpenings.map((job, index) => (
                    <div key={index} className="col-lg-4 col-md-6">
                      <div className="opening_card">
                        <img src={`assets/img/career/${job.img}`} loading="lazy" alt={job.title} />
                        <h4>{job.title}</h4>
                        <div className="text_area">
                          <p>Exp: <span>{job.exp}</span></p>
                        </div>
                        <div className="btn_apply">
                          <a href="#" className="apply btn" data-bs-toggle="modal" data-bs-target="#apply_now">Apply Now</a>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="offer">
            <div className="container">
              <div className="offer_sub">
                <div className="main_heading">
                  <h1>Benefits of joining us</h1>
                </div>
                <div className="offer_for_you">
                  <div className="row g-lg-5">
                    {benefits.map((benefit, index) => (
                      <div key={index} className="col-lg-4 col-md-6">
                        <div className="offer_card">
                          <img src={`assets/img/career/offer/${benefit.img}`} loading="lazy" alt={benefit.title} />
                          <div className="content">
                            <h3>{benefit.title}</h3>
                            <p>{benefit.description}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal" id="apply_now">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">Apply Now</h4>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" />
                </div>
                <div className="modal-body">
                  <div className="contact_frm p-0" style={{ border: 'none' }}>
                    <div className="frm_middle" style={{ margin: 0 }}>
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-lg-6 col-md-6">
                            <div className="form_spacing">
                              <label className="form-label">Your Name</label>
                              <input type="text" value={addData.name} onChange={handleInputChange} className="effect-2 form-control" placeholder="Enter Your Name" name="name" required />
                              {error && !addData.name && <span className='error'>Please enter your name</span>}
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="form_spacing">
                              <label className="form-label">Email Address*</label>
                              <input type="email" value={addData.email} onChange={handleInputChange} className="effect-2 form-control" placeholder="Enter Your Email Address" name="email" required />
                              {error && !addData.email && <span className='error'>Please enter your email</span>}
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="form_spacing">
                              <label className="form-label">Contact Number*</label>
                              <input type="number" value={addData.mobileNumber} onChange={handleInputChange} className="effect-2 form-control" placeholder="+091 123456789" minLength={10} maxLength={10} name="mobileNumber" required />
                              {error && !addData.mobileNumber && <span className='error'>Please enter your number</span>}
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="form_spacing">
                              <label className="form-label">Upload Resume*</label>
                              <div className="input-group custom-file-button">
                                <span className="input-group-text" htmlFor="inputGroupFile">Upload</span>
                                <input type="file" onChange={handleFileChange} className="form-control w-100" id="attachment" name="attachment" required />
                              </div>
                              {error && !publicKey && <span className='error'>Please upload your resume</span>}
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="form_spacing">
                              <label className="form-label">Job Position*</label>
                              <input type="text" value={addData.jobPosition} onChange={handleInputChange} className="effect-2 form-control" placeholder="Enter Job Position" name="jobPosition" required />
                              {error && !addData.jobPosition && <span className='error'>Please enter your job position</span>}
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="form_spacing">
                              <label className="form-label">Message*</label>
                              <input type="text" value={addData.message} onChange={handleInputChange} className="effect-2 form-control" placeholder="Please enter message & comment" name="message" required />
                              {error && !addData.message && <span className='error'>Please enter your message</span>}
                            </div>
                          </div>
                          <div className="col-lg-12 col-md-12">
                            <div className="btn_submit">
                              <button type="submit" className="submit btn">Submit</button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Career;
