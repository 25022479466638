import React from 'react';

const faqItems = [
  {
    id: 'collapseOne',
    question: '1. Why Choose Our Software Products?',
    answer: [
      'Our software products offer several advantages:',
      '- Cutting-edge technology for enhanced performance',
      '- Access to world-class development and support',
      '- Solutions for your unique needs',
      '- Accelerated project delivery',
      '- Resource allocation optimization',
      '- Fresh insights to boost stalled projects'
    ]
  },
  {
    id: 'collapseTwo',
    question: '2. What Is Software Product Development?',
    answer: [
      'Software product development is the process of creating innovative software solutions tailored to your business needs. It involves:',
      '- Conceptualization and design',
      '- Skilled coding and programming',
      '- Rigorous testing and quality assurance',
      '- Ongoing maintenance and updates',
      'We deliver exceptional products that drive your success.'
    ]
  },
  {
    id: 'collapseThree',
    question: '3. How Do Our Software Products Benefit Your Business?',
    answer: [
      'Our software products empower your business by:',
      '- Enhancing operational efficiency',
      '- Boosting productivity and profitability',
      '- Streamlining processes',
      '- Providing data-driven insights',
      '- Keeping you ahead of the competition',
      'We tailor solutions to your unique goals and challenges.'
    ]
  },
  {
    id: 'collapseFour',
    question: '4. How Can I Get Support for Our Software Products?',
    answer: [
      'We provide dedicated support for our software products:',
      '- 24/7 customer assistance',
      '- Regular updates and patches',
      '- Expert guidance and troubleshooting',
      '- Comprehensive documentation',
      'Your success is our priority, and we\'re here to assist you at every step.'
    ]
  },
  {
    id: 'collapseFive',
    question: '5. How Do We Ensure Data Security?',
    answer: [
      'Your data security is paramount to us:',
      '- Robust encryption and authentication',
      '- Regular security audits',
      '- Compliance with industry standards',
      '- Secure data storage and transmission',
      'We take every measure to protect your sensitive information.'
    ]
  }
];

const Faq = () => {
  return (
    <div className=''>
      {/* Start FAQ Area */}
      <div className="faq-area ptb-100" style={{ background: "linear-gradient(#e9f8f8, #fce4fb, #f3ffff)", marginBottom: '100px' }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 mb-4">
              <div className="solution">
                <div className="section-heading mt-4">
                  <h2 className='my-4 text-center' style={{ marginTop: '4%', fontWeight: 'bold' }}>Frequently Asked Questions</h2>
                </div>
                <div className="faq-content shadow-lg">
                  <div className="accordion" id="accordionFAQ">
                    {faqItems.map((item, index) => (
                      <div className="accordion-item" key={index}>
                        <button
                          className={`accordion-button fw-bold ${index === 0 ? '' : 'collapsed'}`}
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#${item.id}`}
                          aria-expanded={index === 0 ? 'true' : 'false'}
                          aria-controls={item.id}
                        >
                          {item.question}
                        </button>
                        <div
                          id={item.id}
                          className={`accordion-collapse collapse ${index === 0 ? 'show' : ''}`}
                          data-bs-parent="#accordionFAQ"
                        >
                          <div className="accordion-body">
                            {item.answer.map((text, textIndex) => (
                              <p key={textIndex}>{text}</p>
                            ))}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End FAQ Area */}
    </div>
  );
}

export default Faq;
