import React from 'react';

const sections = [
  {
    title: 'Information Collection:',
    content: 'We collect personal information such as name, email address, phone number, and company name when you fill out a form or sign up for our newsletter. We may also collect information about your device and IP address when you visit our website.'
  },
  {
    title: 'Use of Information:',
    content: 'We use personal information that we collect to provide services to you, respond to your inquiries, and improve our website. We may also use your personal information to send you marketing communications if you have opted in to receive them.'
  },
  {
    title: 'Sharing of Information:',
    content: 'We do not sell or rent your personal information to third parties. We may share your personal information with service providers who assist us in providing services to you. We may also disclose your personal information to comply with legal requirements or to protect our rights and property.'
  },
  {
    title: 'Data Security:',
    content: 'We take reasonable steps to protect the personal information that we collect against unauthorized access, disclosure, alteration, or destruction. However, no data security measures can guarantee 100% security.'
  },
  {
    title: 'Cookies:',
    content: 'We use cookies on our website to improve user experience and to understand how visitors interact with our website. You can choose to disable cookies in your browser settings.'
  },
  {
    title: 'Policy Changes:',
    content: 'We reserve the right to modify this privacy policy at any time. This page will update with any modifications.'
  },
  {
    title: 'Contact:',
    content: 'If you have any questions or concerns about this privacy policy, please contact us through our website. ',
    link: { href: 'https://lalitmittaltechnologies.com/', text: 'https://lalitmittaltechnologies.com/' }
  }
];

const PrivacyPolicy = () => {
  return (
    <>
      {/* ======= privacy-policy section start=============== */}
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-12 mt-5">
            <div className="section banner">
              <div className="h-100">
                <h1 className="text-dark mb-3" data-aos="fade-up" data-aos-delay="250">
                  Privacy Policy
                </h1>
                <h5 className="text-dark fw-medium mb-4" data-aos="fade-up" data-aos-delay="350">
                  Last updated: October 10, 2023
                </h5>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-12">
            <div className="section job-info pb-0">
              {sections.map((section, index) => (
                <div className="item" key={index}>
                  <div className="title-main">
                    <h4 className="fw-bold mb-0">{section.title}</h4>
                  </div>
                  <div className="col-md-10 mx-auto">
                    <h6>
                      {section.content}
                      {section.link && (
                        <a href={section.link.href} target="_blank" rel="noopener noreferrer">
                          {section.link.text}
                        </a>
                      )}
                    </h6>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* ======= privacy-policy section end=============== */}
    </>
  );
};

export default PrivacyPolicy;
