import React from 'react';

const ErrorPage = () => {
  return (
    // Start of 404 section
    <div 
      className="bg-dark" 
      style={{ 
        background: 'url("assets/img/error/error.svg") no-repeat center center / cover', 
        color: '#3A3B87', 
        height: '100vh', 
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'center' 
      }}
    >
      <section className="container">
        <div className="text-center">
          <h1 className="display-1 fw-bold text-light">404</h1>
          <h2 className="display-4 fw-bold text-white">Not Found</h2>
          <p className="lead text-white">The page you were looking for could not be found.</p>
          <a href="/">
            <button className="btn btn-light btn-lg mt-5">Return To Home Page</button>
          </a>
        </div>
      </section>
    </div>
    // End of 404 section
  );
};

export default ErrorPage;
